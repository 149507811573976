<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="800"
    scrollable
    persistent
  >
    <ShortcutForm
      :shortcut="editedShortcut"
      :disabled="$store.getters.loading[`put:api/shortcut/${editedShortcut.id}`]"
      :errors="shortcutValidationErrors"
      @clear:errors="CLEAR_SHORTCUT_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import ShortcutForm from '@/components/forms/ShortcutForm';

export default {
  name: 'EditShortcut',

  components: { ShortcutForm },

  computed: {
    ...mapState('shortcuts', ['editedShortcut', 'shortcutValidationErrors']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('shortcuts/editShortcut', +to.params.shortcutId)
      .then(() => {
        next();
      })
      .catch(() => {
        next({
          name: 'profile',
        });
      });
  },

  methods: {
    ...mapActions('shortcuts', ['updateShortcut']),
    ...mapMutations('shortcuts', ['CLEAR_SHORTCUT_VALIDATION_ERRORS']),

    onSave(shortcut) {
      this.updateShortcut(shortcut).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push({ name: 'profile' });
    },
  },
};
</script>
